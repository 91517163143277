.header-image {
  position: relative;
  height: 460px;

  // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
  @include media-breakpoint-down(sm) {
    height: 80vw;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .header-image-title {
    @include absolute-position($top: 50%);
    font-size: rem-calc(60px);
    color: white;
    width: 100%;
    padding: 0 5%;
    margin: 0 auto;
    text-align: center;

    // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
    @include media-breakpoint-down(sm) {
      font-size: rem-calc(46px);
    }
  }
}

section.content {
  @include padding-top-resp($xs: 40px, $sm: 50px, $md: 80px);
  @include padding-bottom-resp($xs: 40px, $sm: 50px, $md: 80px);
  // > .container {
  //   // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
  //   @include media-breakpoint-only(xxl) {
  //     max-width: calc(1140px + 3rem); // 3rem -> compensate for --bs-gutter-x
  //   }
  // }

  .row {
    --bs-gutter-x: 6rem;

    aside {

      // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
      @include media-breakpoint-up(lg) {
        border-left: 2px solid $theme-pink-light;
      }

      @include media-breakpoint-down(lg) {
        margin-top: 60px;
        border-top: 2px solid $theme-pink-light;
        padding-top: 30px;
      }

      .sidebar-nav {
        >ul {

          // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
          @include media-breakpoint-up(lg) {
            padding-left: 2rem;
          }

          >li {
            font-size: rem-calc(20px);
            font-weight: 600;
            padding-bottom: 1.2rem;

            &.active {
              color: $theme-pink-light;
            }
          }

          >ul {
            padding-bottom: 1.3rem;

            >li {
              font-size: rem-calc(16px);

              &:not(:last-child) {
                padding-bottom: .8rem;
              }

              &::before {
                display: inline-block;
                margin-right: 10px;
                content: '\2010'
              }
            }
          }
        }
      }
    }
  }
}