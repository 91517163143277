a.normal-text-color {
    color: unset !important;
    text-decoration: none !important;
}

#navbarNav {
    &.collapse.show {
        ul.dropdown-menu {
            padding-left: 1rem;

            .nav-link {
                font-weight: 400;
            }
        }
    }

    li.nav-item.dropdown ul.dropdown-menu {
        padding-right: 25px;
    }
}

footer {
    .logos-track {
        .wrapper {
            justify-content: center;

            img {
                margin: 10px 18px;
            }
        }
    }
}

.clickable {
    :hover {
        cursor: pointer;
    }
}

.sidebar-nav li>a {
    color: unset;
}

.btn-theme-black {
    display: block;
    background-color: black;
    width: 100%;
    color: #fff;
    border: none;
    padding: 0.5rem 2.25rem;
    margin: 4rem 0 3rem 0;
    border-radius: 6px;
    font-size: 1.25rem;
    font-weight: 500;
    transition: all .3s ease-in-out;
}



.offcanvas.sidebar-nav {
    .sidebar-nav-item {
        ul.style-none {
            text-align: left;
            margin-top: 2.5rem;
        }

        .text {
            text-align: left;
            line-height: 0.5rem;
        }

    }
}

main {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ul,
    ol {
        margin-bottom: 1.5rem !important;
    }

    ul:not(.sidebar-nav ul),
    ol {
        font-size: 1.25rem;
        line-height: 2.25rem;
        font-weight: 300;
        color: #4a4a4a;
    }
}

strong {
    font-weight: 600 !important;
}

aside {
    .sidebar-nav {
        ul.submenu {
            list-style: none;
            margin-bottom: 0px !important;
            // padding-top: 1.2rem;

            li {
                margin-top: 1.2rem;

                >a {
                    font-weight: 400;
                }
            }
        }

        li.current>a {
            color: #ee287f;
        }
    }
}

.news-main {
    .news-card__type-2 {
        .tag {
            top: -35px;
            left: 10px;
        }
    }
}

.listings-list,
.accommodations-list {
    .job-offer-card {
        border: 1px solid #d2cfcf;
        border-radius: 12px;
        margin-bottom: 40px;
        padding: 30px;
        position: relative;

        &.urgent {
            border: 2px solid #ee287f;
        }

        .tag {
            @include pill-wrap('10%/50%');
            background: $theme-pink-light;

            &__blue {
                background: $theme-blue-dark;
            }

            text-transform: uppercase;
            padding: 0 8px;
            color: white;
            font-size: rem-calc(16px);
            font-weight: 500;
            line-height: rem-calc(24px);
            position: absolute;
            right: 5px;
            top: 5px;
        }

        .title {
            /* font-size: 2.6rem;
            line-height: 2.6rem; */
            font-size: 2rem !important;
            font-weight: 500;
            align-items: flex-start;
            justify-content: space-between;
        }

        .employer {
            font-size: unset;
        }

        .bottom {
            padding-top: 25px;

            .bottom-inner {
                display: inline-flex;
                width: 100%;
                align-items: first baseline;
                justify-content: space-between;
                border-top: 1px solid lightgray;
                padding-top: 30px;

                .detail-link {
                    display: flex;
                    align-items: center;

                    &:hover {
                        cursor: pointer;
                    }

                    img {
                        margin-left: 0.8rem;
                    }
                }

            }

            .price {
                font-family: 'Zilla Slab';
            }
        }

    }
}

@media screen and (max-width: 576px) {
    .accommodations-list {
        .job-offer-card .bottom {
            flex-direction: column;
        }
    }
}

.listing-detail,
.accommodation-detail {
    .header {
        background: #F9F2EC;
        padding: 50px;
        margin-bottom: 1.5rem;
    }

    h1.title {
        font-size: 2rem;
    }

    .date-wrapper {
        // margin-bottom: 2rem;

        .date {
            color: #4a4a4a;
        }
    }

    table {
        margin-top: 2rem;

        th {
            width: 60%;
            font-weight: 600;
            font-size: 1.25rem;
        }

        td {
            width: 40%;
            font-size: 1.25rem;
        }
    }
}

.pagination {
    .page-item {
        &.disabled {
            opacity: 0.5;
        }
    }
}

#section__1 {
    .checklist-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

// xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
@include media-breakpoint-down(lg) {

    #section__1 .row,
    #section__2 .row:first-child,
    #section__2 .row,
    #section__3 .row,
    section.content .row,
    .row {
        --bs-gutter-x: 0px !important;
    }
}

.row.images-wrapper {
    --bs-gutter-x: 0px !important;
}

.gallery {
    .col {
        margin-bottom: 24px;
        padding: 0 12px;
    }

    .photo {
        margin: 3px;
    }

    &.news-detail-gallery {
        margin-bottom: 1.5rem;
    }
}

.poll {
    margin-top: 100px;
    margin-left: 2rem;
    padding-top: 50px;
    border-top: 1px solid #e6e6e6;

    h3 {
        font-family: 'Poppins';
        font-size: 1.125rem;
        font-weight: 500;
        color: #ee287f;
        letter-spacing: 2.5px;
        text-transform: uppercase;
    }

    h5 {
        font-family: 'Poppins';
        font-size: 1.125rem;
        font-weight: 500;
    }

    form {
        .ui.radio.checkbox.choice-item {
            display: flex;
            margin-bottom: 25px;
            font-size: 15px;
        }

        input[type='radio'] {
            margin-right: 0.5rem;
        }

        .btn {
            background-color: #ee287f;
            color: white;
        }
    }

    .results-header {
        text-align: center;
        font-weight: bold;
    }

    .statistics {
        &.result {
            margin-bottom: 1rem;

            .value-wrap {
                background-color: #ededed;
            }

            .value {
                position: relative;
                top: -3px;
                height: 5px;
                background-color: #ee287f;
                border-radius: 5px;
            }
        }
    }
}

.btn-theme-pink {
    background-color: #ee287f;
    color: #fff;
    border: none;
    padding: 0.7rem 2.25rem;
    border-radius: 6px;
    font-size: 1.25rem;
    font-weight: 500;
    transition: all .3s ease-in-out;
}

i.bi-file-earmark-arrow-down {
    margin-right: 0.5rem;
}

.listings-list {
    #filter-form {
        display: flex;
        margin-bottom: 1.5rem;

        span.form-title {
            font-family: Poppins;
            font-size: 1.125rem;
            font-weight: 600;
            letter-spacing: 2.5px;
        }

        >* {
            margin-right: 1rem;
        }

        select {
            &#id_place_filter {
                width: 30%;
            }

            &#id_job_group_filter {
                width: 30%;
            }

            &#id_price_filter {
                width: 30%;
            }
        }

        // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
        @include media-breakpoint-down(md) {
            flex-direction: column;

            select {
                margin-bottom: 0.5rem;

                &#id_place_filter {
                    width: 100%;
                }

                &#id_job_group_filter {
                    width: 100%;
                }

                &#id_price_filter {
                    width: 100%;
                }
            }
        }
    }
}

form {
    .errorlist {
        list-style: none;
        position: relative;
        top: -30px;
        padding-left: 0px;


        li {
            color: red;
            font-size: 1rem;
        }
    }

    .help {
        position: relative;
        top: -30px;
        font-size: 0.7rem;
    }

    .fieldWrapper {
        ul {
            list-style: none;
        }
    }
}

.chat-app-icon {
    height: 25px;
    margin-left: 5px;
}

.job-offers-carousel {
    .job-offer-card {
        &.urgent {
            border: 2px solid #ee287f;
        }
    }
}

#map {
    .leaflet-control-attribution.leaflet-control a {
        font-size: 12px;
    }
}

#section__3 .info>.row>.col:first-of-type .wrapper .text h3 {
    letter-spacing: 2px;
}

.carousel-actual-denested {
    z-index: 1;
    position: relative;
    height: 196px;
    top: -196px;
    background-color: $theme-yellow;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    padding: 2rem;
    width: 90%;

    // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
    @include media-breakpoint-down(sm) {
        height: 252px;
        top: -252px;

    }

    #notifications-carousel {
        .carousel-inner {
            .carousel-item {
                min-height: 140px;

                @include media-breakpoint-between(sm, lg) {
                    padding-left: 15px;
                }
            }
        }
    }

    @media screen and (min-width: 1100px) {
        width: 1000px;
    }

    .row {
        >div:first-child {
            margin-bottom: 1rem;
            justify-content: center;
            justify-items: center;
            display: inline-grid !important;
            align-content: center;

            // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
            @include media-breakpoint-up(sm) {
                border-right: 4px solid $theme-pink-light;
                margin-bottom: 0px;
            }
        }
    }

    .meta {
        display: flex;
        font-size: 1rem;
        text-transform: uppercase;
        line-height: rem-calc(14px);
        margin-bottom: 10px;

        @include media-breakpoint-down(sm) {
            font-size: 0.8rem;
        }

        >div:not(:last-child) {
            padding-right: .5rem;
            margin-right: .5rem;
            border-right: 4px solid $theme-pink-light;
        }
    }

    .main {
        display: flex;
        align-items: flex-end;

        .text {
            font-weight: 600;
            font-size: 24px;

            // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
            @include media-breakpoint-down(sm) {
                font-size: 1.1rem;
                line-height: 1.2rem;
            }
        }

        a {
            @extend .text-reset;

            i {
                font-size: rem-calc(30px);
            }
        }
    }

    .carousel-indicators {
        bottom: -30px;
        justify-content: end;
        margin-right: 0px;

        @include media-breakpoint-only(xs) {
            bottom: -15px;
        }

        button {
            background-color: black;
            width: 10px;
            height: 10px;
            border-radius: 100%;
        }

    }
}

#carousels-wrapper {
    height: 900px;
}

.js-captcha-refresh {
    font-size: 0.9rem;
}

.video-aspect-16-9 {
    position: relative;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
}
.video-aspect-16-9 iframe,
.video-aspect-16-9 video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}