.daily-menu {
    background: url("../../../img/menu-bg-1.jpg") no-repeat bottom center;
    background-size: cover;
    min-height: 620px;
    position: relative;
    margin-bottom: 2rem;

    h1 {
        padding-top: 3rem;
        margin-bottom: 0px !important;
    }

    ul {
        position: relative;
        // font-family: @font-sans;
        text-transform: uppercase;
        text-align: center;
        padding: 0px 0;
        z-index: 100;

        li {
            display: inline-block;
            margin: 0 25px;

            a {
                display: block;
                transition: all 0.2s;

                &:hover {
                    color: #AF034F;
                }

                &.active {
                    font-weight: bold;
                }

                &.disabled {
                    color: #ccc;
                    pointer-events: none;

                    &:hover {
                        color: inherit;
                        text-decoration: none;
                    }
                }
            }
        }
    }

    .column-wrapper {
        position: absolute;
        bottom: 0px;
        width: 100%;
        box-sizing: border-box;
        font-size: 0;
        line-height: 0;
        text-align: center;
    }

    .col {
        // font-family: @font-sans;
        font-weight: 300;
        font-size: 0.95rem;
        line-height: 180%;
        display: inline-block;
        width: 20%;
        margin: 0 0.5%;
        background: rgba(255, 255, 255, 0.96);
        padding: 30px 20px 0 20px;
        text-align: left;

        @media (max-width: 1200px) {
            border: 1px;
            border-style: solid;
            border-color: black;
        }

        p {
            font-size: 16px;
            line-height: 130%;
            margin-bottom: 12px;
        }

        .number {
            font-size: 1.6rem;
            font-weight: 400;
            text-align: center;
            color: #D48071;
            padding: 30px 0 20px 0;
            text-transform: uppercase;
        }

        span {
            font-size: 10px;
            font-weight: bold;
        }
    }

    .day {
        display: none;

        &.active {
            display: block;
        }
    }

    // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
    @include media-breakpoint-down(lg) {
        h1 {
            margin-bottom: 15px;
        }

        ul {
            li {
                margin: 0 5px;
                font-size: 12px;
            }
        }

        .col {
            width: 100%;
            margin: 0 0 10px 0;
            padding: 25px;

            p {
                text-align: center;
            }
        }

        .column-wrapper {
            position: relative;
            bottom: auto;
        }
    }

}