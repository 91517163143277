// Theme default styles

/*^^^^^^^^^^^^^^^^^^^^^ navbar.css ^^^^^^^^^^^^^^^^^^^^^^^^*/


.navbar-toggler {
  width: 32px;
  height: 24px;
  box-shadow: none;
  position: relative;
  z-index: 99;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.navbar-toggler:focus {box-shadow: none;}
.navbar-toggler::before,
.navbar-toggler::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  pointer-events: none;
  -webkit-transition: -webkit-transform 0.25s;
  transition: transform 0.25s;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  background: #000;
}
.navbar-toggler span {
  position: absolute;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 2px;
  text-indent: 200%;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
  background: #000;
}
.navbar-toggler::before {
  -webkit-transform: translate3d(0, -9px, 0) scale3d(1, 1, 1);
  transform: translate3d(0, -9px, 0) scale3d(1, 1, 1);
}
.navbar-toggler::after {
  -webkit-transform: translate3d(0, 9px, 0) scale3d(1, 1, 1);
   transform: translate3d(0, 9px, 0) scale3d(1, 1, 1);
}
.navbar-toggler[aria-expanded="true"] span {opacity: 0;}
.navbar-toggler[aria-expanded="true"]::before {
  -webkit-transform: rotate3d(0, 0, 1, 45deg);
  transform: rotate3d(0, 0, 1, 45deg);
}
.navbar-toggler[aria-expanded="true"]::after {
  -webkit-transform: rotate3d(0, 0, 1, -45deg);
  transform: rotate3d(0, 0, 1, -45deg);
}
.navbar .navbar-nav .nav-link {
	font-family: $font-poppins;
  font-weight: 600;
	font-size: rem-calc(18px);
	color: #000;
	padding: 15px 0;
	margin: 0 25px;
}
.navbar .navbar-nav .active .nav-link,
.navbar .navbar-nav .nav-item:hover .nav-link,
.white-vr .navbar .navbar-nav .active .nav-link,
.white-vr .navbar .navbar-nav .nav-item:hover .nav-link {color: var(--red-one);}
.navbar .dropdown-menu .dropdown-item {
	font-size: rem-calc(16px);
	padding:0 15px 0 0;
	letter-spacing: 0px;
	text-transform: capitalize;
	line-height: 42px;
	color: #212121;
	background: transparent;
	display: inline-block;
	position: relative;
	transition: all 0.3s ease-in-out;
	white-space: inherit;
}
.navbar .dropdown-menu .dropdown-item .tag {
	position: absolute;
  background: var(--red-one);
  line-height: 13px;
  border-radius: 2px;
  color: #fff;
  font-size: rem-calc(10px);
  letter-spacing: 1px;
  padding: 0 4px;
  text-transform: uppercase;
  bottom: 12px;
  right: 0;
  font-style: normal;
}
.navbar .dropdown-menu .dropdown-item>span {position: relative;}
.navbar .dropdown-menu .dropdown-item>span:before {
	content: '';
	position: absolute;
	width: 100%;
	height: 2px;
	left: 0;
	bottom: -2px;
	background: var(--red-one);
	transform: scale(0 , 1);
	transform-origin: 0 0;
	transition: all 0.3s ease-in-out;
}
.navbar .dropdown-menu .dropdown-item:hover >span:before,
.navbar .dropdown-menu .dropdown-item.active >span:before,
.navbar .dropdown-menu .dropdown:hover> .dropdown-item>span:before {transform: scale(1);}
.navbar .dropdown-menu .dropdown:hover>.dropdown-item,
.navbar .dropdown-menu .dropdown-item.active,
.navbar .dropdown-menu .dropdown-item:hover {color: var(--red-one); letter-spacing: 0.2px;}

.navbar .dropdown-toggle::after {
  content: "\f282";
  font-family: bootstrap-icons;
  border:none !important;
  font-size: rem-calc(15px);
  font-weight: 700;
  position: absolute;
  right: 0;
  line-height: 1.5;
  padding: 10px 0;
  top:0;
  transition: all 0.3s ease;
  color: inherit;
}
.navbar .show.dropdown-toggle::after {transform: rotate(180deg);}
.navbar .dropdown-submenu .dropdown-toggle::after {
  content: '+';
  font-family: 'Recoleta';
  display: block;
  right: 20px;
  top: 0;
  line-height: 42px;
  font-weight: normal;
  font-size: rem-calc(25px);
  transition: transform 0.3s ease-in-out;
}
.navbar .dropdown-submenu:hover>.dropdown-toggle::after {transform: rotate(45deg);}
/*------Mega Menu ------*/
.navbar [class*="mega-dropdown"] .dropdown-menu {padding: 0 10px; margin: 0;}
.navbar [class*="mega-dropdown"] .row [class*="col-"] {border-left: 1px solid #F2F2F2;}
.navbar [class*="mega-dropdown"] .row [class*="col-"]:first-child {border: none;}
.navbar [class*="mega-dropdown"] .menu-column {padding: 10px 10px 25px 20px;}
.navbar [class*="mega-dropdown"] .mega-menu-title {
	font-family: 'gorditabold';
	font-size: rem-calc(13px);
	letter-spacing: 1.3px;
	text-transform: uppercase;
	display: inline-block;
	position: relative;
	margin: 20px 0 25px;
}
.navbar [class*="mega-dropdown"] .mega-menu-title:before {
	content: '';
	position: absolute;
	width: 100%;
	height: 2px;
	background: var(--red-one);
	left: 0;
	bottom: -5px;
}
.navbar [class*="mega-dropdown"] .dropdown-item {line-height: 40px; padding: 0;}
/*------ Home Dropdown ------*/
.navbar .home-sub-menu .img-meta {
	border: 1px solid rgba(0, 0, 0, 0.03);
	border-radius: 10px;
	overflow: hidden;
	position: relative;
}
.navbar .home-sub-menu .img-meta .cs {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	font-size: rem-calc(20px);
	color: #fff;
}
.navbar .home-sub-menu .img-meta img {width: 100%;}
.navbar .home-sub-menu .img-meta:hover img {transform: scale3d(1.1, 1.1, 1.0);}
.navbar .home-sub-menu .page-info {
	font-size: rem-calc(17px);
	line-height: 1.7em;
	margin: 35px 0 0;
}
.navbar .home-sub-menu .address-block p {
  font-size: rem-calc(19px);
  line-height: 1.6em;
  color: var(--heading);
  margin-bottom: 30px;
}
.navbar .home-sub-menu .address-block p a {font-size: rem-calc(21px); color: var(--red-one); margin-top: 4px;}
.navbar .home-sub-menu .address-block p a:hover {text-decoration: underline;}

/*----- Dark Dropdown ----*/
.dark-dropdown .navbar .dropdown-menu {
	background: #181818;
	box-shadow: 0px 50px 100px rgb(0 0 0 / 12%);
	border: 1px solid rgba(255, 255, 255, 0.015);;
}
.dark-dropdown .navbar .dropdown-menu .dropdown-item,
.dark-dropdown .navbar .home-sub-menu .page-info {color: rgba(255, 255, 255, 0.85);}
.dark-dropdown .navbar .dropdown-menu .dropdown-item:hover,
.dark-dropdown .navbar .dropdown-menu .dropdown:hover>.dropdown-item,
.dark-dropdown .navbar .dropdown-menu .dropdown-item.current,
.dark-dropdown .navbar [class*="mega-dropdown"] .mega-menu-title {color: #fff;}
.dark-dropdown .navbar .home-sub-menu .address-block p {color: rgba(255, 255, 255, 0.7);}
.dark-dropdown .navbar .home-sub-menu .img-meta {border: 1px solid rgba(255, 255, 255, 0.05);}
.dark-dropdown .navbar .dropdown-menu.home-sub-menu {border-left: 1px solid rgba(255, 255, 255, 0.05);}
.dark-dropdown .navbar [class*="mega-dropdown"] .row [class*="col-"] {border-left: 1px solid rgba(255, 255, 255, 0.05);}
.dark-dropdown .navbar [class*="mega-dropdown"] .row [class*="col-"]:first-child {border: none;}
/*----- Icon Menu ----*/
.navbar .icon-menu .dropdown-menu li {
	border-bottom: 1px solid #ebebeb;
	padding: 15px 0;
}
.navbar .icon-menu .dropdown-menu li:last-child {border-bottom: 0;}
.navbar .icon-menu .dropdown-menu .icon {
	width: 28px;
	height: 28px;
	display: flex;
	align-items: flex-start;
	justify-content: flex-end;
	margin-top: 5px;
}
.navbar .icon-menu .dropdown-menu .icon img {max-height: 100%;}
.navbar .icon-menu .dropdown-menu .page-title {
	font-family: $font-poppins;
  font-weight: 600;
	font-size: rem-calc(17px);
	letter-spacing: -0.5px;
	color: #000;
}
.navbar .icon-menu .dropdown-menu .page-title:hover {text-decoration: underline;}
.navbar .icon-menu .dropdown-menu .xs-text {
	display: block;
	font-size: rem-calc(15px);
	line-height: 1.7em;
	letter-spacing: -0.3px;
	color: #989898;
	margin-top: -2px;
}
.navbar .icon-menu .dropdown-menu .xs-text a {color: #000; text-decoration: underline; transition: all 0.3s ease-in-out;}
.navbar .icon-menu .dropdown-menu .xs-text a:hover {color: var(--red-one);}
/*----- For Desktop -------*/
@media screen and (min-width: 992px) {
	.navbar .dropdown-toggle::after {display: none;}
	.navbar .dropdown-menu {
			z-index: 5;
	    background-color: #fff;
	    border-radius: 0;
	    display: block;
	    right: 0;
	    left: 0;
	    padding: 20px 0 25px 25px;
	    border: none;
	    top: 100%;
	    visibility: hidden;
	    transform: scale(1 , 0);
	    opacity: 0;
	    min-width: 240px;
			box-shadow: 0px 50px 100px rgba(0, 0, 0, 0.12);
	    margin: 0;
	    transform-origin: 0 0;
	    transition: all 0.3s ease-in-out;
	}
	.navbar .dropdown-menu .dropdown-menu {
		left: 100%;
		top: 0;
		right: auto;
		min-width: 230px;
		padding-left: 20px;
		box-shadow: 0 20px 30px -10px rgb(0, 0, 0, 0.15);
		transform: scale(0 , 1);
	}
	.navbar .dropdown:hover>.dropdown-menu {
		opacity: 1;
		visibility: visible;
		transform: scale(1);
	}
	.navbar .mega-dropdown-md .dropdown-menu {min-width: 850px; left: -425px;}
	.navbar .mega-dropdown-sm .dropdown-menu {min-width: 570px; left: -230px;}
	.navbar .dropdown-menu.home-sub-menu {
		min-width: 450px;
		border-left: 1px solid #f1f1f1;
		padding: 30px 40px;
		transform: scale(1);
		box-shadow: 0 20px 30px -30px rgb(0, 0, 0, 0.15);
	}
	/*Icon Menu*/
	.navbar .icon-menu .dropdown-menu {
		min-width: 350px;
		box-shadow: 0px 50px 100px rgba(0, 0, 0, 0.05);
		left: -100px;
		padding: 10px 20px;
		/*opacity: 1;
    visibility: visible;
    transform: none;*/
	}

}
/*(min-width: 992px)*/

/*----- For Mobile ----*/
@media screen and (max-width: 991px) {
	.navbar-collapse .logo {margin-bottom: 8vh;}
  .navbar-collapse {
    position: fixed;
    top: 0;
    background-color: #ffffff;
    left: 0;
    height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
    clear: both;
    width: 320px;
    max-width: calc(100vw - 50px);
    z-index: 9999;
    transform: translateX(-100%);
    display: block !important;
    padding: 25px 15px 20px;
    transition: all 0.3s ease-in-out;
  }
  .navbar-collapse.show {transform: translateX(0); box-shadow: 15px 0 25px rgba(35,35,35,0.1);}
  .theme-main-menu .navbar .mega-dropdown {position: relative;}
  .navbar .navbar-nav .nav-link {margin: 0; padding: 10px 0; font-size: rem-calc(16px);}
  .navbar .dropdown-menu .dropdown-item {font-size: rem-calc(15px); padding: 0 5px 0 10px;}
  .navbar .dropdown-menu .menu-column .dropdown-item {padding-left: 0;}
  .navbar .dropdown-menu {
  	border: none;
  	padding: 0;
  	border-radius: 0;
  	background: var(--light-blue);
  }
  .navbar .dropdown-submenu .dropdown-menu {margin: 0; padding: 0 0 0 20px; background: var(--deep-blue);}
  .dark-dropdown .navbar .dropdown-submenu .dropdown-menu {background: #000;}
  .navbar .icon-menu .dropdown-menu {padding: 0;}
  .navbar .icon-menu .dropdown-menu li {padding: 12px 5px 12px 15px;}
  /*--mega menu*/
  .navbar [class*="mega-dropdown"] .row [class*="col-"],.dark-dropdown .navbar [class*="mega-dropdown"] .row [class*="col-"] {border: none;}
  .navbar [class*="mega-dropdown"] .menu-column {padding: 0;}
  .navbar [class*="mega-dropdown"] .mega-menu-title {
  	display: block;
  	margin: 0;
  	padding: 15px 0 5px;
  	font-size: rem-calc(12px);
  	letter-spacing: 1px;
  }
  .navbar [class*="mega-dropdown"] .mega-menu-title:before {display: none;}

	.theme-menu-five .navbar-toggler span,
	.theme-menu-five .navbar-toggler::before,.theme-menu-five .navbar-toggler::after,
	.white-vr .navbar-toggler span,.white-vr .navbar-toggler::before,.white-vr .navbar-toggler::after {background: #fff;}
	.theme-main-menu .dark-style,.navbar .dark-style .dropdown-submenu .dropdown-menu {background: #181818;}
	.navbar .dark-style .dropdown-menu {background: #000;}
	.navbar .dark-style .navbar-nav .nav-link,
	.navbar .dark-style [class*="mega-dropdown"] .mega-menu-title {color: #fff;}
	.navbar .dark-style .dropdown-menu .dropdown-item {color: rgba(255, 255, 255, 0.75);}

	.navbar .dark-style .navbar-nav .active .nav-link {color: var(--red-one);}
}
/*(max-width: 991px)*/

.navbar {
  .navbar-nav {
    column-gap: 3vw;
    margin-left: 20px;
    margin-right: 20px;
    .nav-link {
      margin: 0;
    }
  }
  .navbar-toggler {
    right: 25px;
    border: 0 !important;
    @media (max-width: 400px) {
      right: 10px;
    }
  }
}

/*^^^^^^^^^^^^^^^^^^^^^ navbar.css end ^^^^^^^^^^^^^^^^^^^^^^^^*/






/*^^^^^^^^^^^^^^^^^^^^^ Theme Menu ^^^^^^^^^^^^^^^^^^^^^^^^*/
.theme-main-menu {
  position: fixed;
  z-index: 99;
  top:0;
  left:0;
  right: 0;
  padding: 0;
  transition: all 0.4s ease-out;
  .inner-content {
    background: rgba(255,255,255,.6);
    border-radius: 45px;
    padding: 10px 30px 10px 15px;
    margin-top: 40px;
    margin-left: 30px;
    margin-right: 30px;
    transition: margin 700ms;
    @media (max-width: 420px) {
      margin-left: 0;
      margin-right: 0;
      margin-top: 0;
      border-radius: 0;
    }
  }
  &.with-margin-top-decreased {
    .inner-content {
      margin-top: 20px;
      background: none;
    }
  }
}
.theme-main-menu.sticky-menu.fixed {
  z-index: 990;
  padding-top: 8px;
  padding-bottom: 8px;
  background: #fff;
  box-shadow: 0 13px 35px -12px rgba(35,35,35,0.1);
}
.theme-main-menu .inner-content {position: relative;}
.theme-main-menu .navbar,.theme-main-menu .mega-dropdown {position: static;}
.theme-main-menu.sticky-menu.fixed.white-vr,.theme-main-menu.sticky-menu.fixed.dark-bg {background: #191919;}
.theme-main-menu .logo-xl {width: 203px;}
.theme-menu-one .right-widget .login-btn {margin-right: 60px;}
.theme-menu-one .right-widget .login-btn span {
  font-family: $font-poppins;
  font-weight: 600;
  margin: 0 0 0 12px;
  transition: all 0.3s ease-in-out;
  color: var(--heading);
}
.theme-menu-one .right-widget .login-btn:hover span {text-decoration: underline;}
.theme-menu-one .right-widget .contact-fancy-btn {
  font-family: $font-poppins;
  font-weight: 600;
  color: var(--heading);
  font-size: 16px;
  position: relative;
  z-index: 1;
}
.theme-menu-one .right-widget .contact-fancy-btn em {
  position: relative;
  display: inline-block;
}
.theme-menu-one .right-widget .contact-fancy-btn em:after,
.theme-menu-one .right-widget .contact-fancy-btn span:after {
  content: '';
  position: absolute;
  width: 107%;
  height: 2px;
  left: 0;
  bottom: 0;
  background: #000;
}
.theme-menu-one .right-widget .contact-fancy-btn span {
  display: inline-block;
  color: #fff;
  position: relative;
  padding-left: 5px;
}
.theme-menu-one .right-widget .contact-fancy-btn span:before {
  content: '';
  position: absolute;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: #000;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
  right: -36px;
  transition: all 0.3s ease-in-out;
}
.theme-menu-one .right-widget .contact-fancy-btn:hover span:before {background: var(--orange-one);}
.theme-menu-one .right-widget .contact-fancy-btn span:after {background: #fff;}
.theme-menu-one .right-widget .contact-fancy-btn i {color: #fff; margin-left: 3px;}
.white-vr .navbar .navbar-nav .nav-link,
.theme-menu-one.white-vr .right-widget .login-btn span {color: #fff;}
.theme-main-menu .right-widget .cart-group-wrapper .dropdown-toggle:after {display: none;}
.theme-main-menu .right-widget .cart-group-wrapper .dropdown-toggle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 30px 0 -30px;
  position: relative;
  transition: all 0.3s ease-in-out;
}
.theme-main-menu .right-widget .cart-group-wrapper .dropdown-toggle:hover {background: rgba(0, 0, 0, 0.04);}
.theme-main-menu .right-widget .cart-group-wrapper .item-count {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: var(--red-one);
  line-height: 16px;
  text-align: center;
  font-size: 9px;
  color: #fff;
  right: 2px;
  top:0px;
}
.theme-main-menu .cart-group-wrapper .dropdown-menu {
  width: 300px;
  background: #fff;
  border: none;
  border-radius: 0;
  padding: 30px 35px 40px;
  box-shadow: 0px 25px 50px rgba(213, 216, 223, 0.5);
}
.theme-main-menu .right-widget .cart-group-wrapper .item-img {
  width: 70px;
  height: 75px;
  padding: 5px;
  background: #F5F5F5;
}
.theme-main-menu .right-widget .cart-group-wrapper .item-info {
  width: calc(100% - 70px);
  padding-left: 20px;
  position: relative;
}
.theme-main-menu .right-widget .cart-group-wrapper .item-info .name {
  font-size: 17px;
  color: #1f1f1f;
  margin: 0 0 5px;
}
.theme-main-menu .right-widget .cart-group-wrapper .item-info .name:hover {text-decoration: underline;}
.theme-main-menu .right-widget .cart-group-wrapper .item-info .price {
  font-family: $font-poppins;
  font-weight: 600;
  font-size: 18px;
  color: #000;
  letter-spacing: -1px;
}
.theme-main-menu .right-widget .cart-group-wrapper .item-info .price .quantity {
  font-size: 17px;
  color: rgba(31,31,31,0.3);
  margin-left: 7px;
}
.theme-main-menu .right-widget .cart-group-wrapper .selected-item {
  border-bottom: 1px solid #EEEEEE;
  padding-bottom: 30px;
  margin-bottom: 22px;
}
.theme-main-menu .right-widget .cart-group-wrapper .selected-item:last-child {margin-bottom: 21px;}
.theme-main-menu .right-widget .cart-group-wrapper .subtotal .title {font-size: 18px;color: #1f1f1f}
.theme-main-menu .right-widget .cart-group-wrapper .subtotal .total-price {
  font-family: $font-poppins;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0px;
  color: #000;
}
.theme-main-menu .right-widget .cart-group-wrapper .subtotal {padding-bottom: 7px;}
.theme-main-menu .right-widget .cart-group-wrapper .button-group a {
  font-family: $font-poppins;
  font-weight: 600;
  display: block;
  line-height: 43px;
  border: 1px solid #dbdbdb;
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
  color: #1f1f1f;
  letter-spacing: 1px;
  margin-top: 15px;
  transition: all 0.3s ease-in-out;
}
.theme-main-menu .right-widget .cart-group-wrapper .button-group a:hover {color: #fff; background: #212121;}
.theme-main-menu .right-widget .eCommerce-search {width: 335px; position: relative;}
.theme-main-menu .right-widget .eCommerce-search input {
  width: 100%;
  font-size: 17px;
  border: none;
  font-style: italic;
  padding-left:35px;
  padding-right: 15px;
  background: transparent;
}
.theme-main-menu .right-widget .eCommerce-search .icon {
  position: absolute;
  left:0;
  top:50%;
  transform: translateY(-50%);
}
.theme-main-menu .user-profile-action .dropdown-toggle:after {display: none;}
.theme-main-menu .user-profile-action .dropdown-menu {
  width: 220px;
  background: #fff;
  border: none;
  border-radius: 0;
  padding: 20px 10px 25px 30px;
  box-shadow: 0px 25px 50px rgba(213, 216, 223, 0.5);
}
.theme-main-menu .user-profile-action .dropdown-menu ul li a {
  font-size: 17px;
  line-height: 42px;
  color: #000;
  padding-left: 30px;
  position: relative;
  transition: all 0.3s ease-in-out;
}
.theme-main-menu .user-profile-action .dropdown-menu ul li a:hover {color: var(--red-one);}
.theme-main-menu .user-profile-action .dropdown-menu ul li a .icon {
  position: absolute;
  left:0;
  top:12px;
}
.theme-main-menu .user-profile-action .dropdown-menu ul li a:hover .icon path {fill: var(--red-one);}
.theme-main-menu .mobile-content .search-form {
  margin: 40px auto 40px;
  width: 100%;
  position: relative;
}
.theme-main-menu .mobile-content .search-form input {
  width: 100%;
  height: 50px;
  border: 2px solid var(--heading);
  border-radius: 6px;
  font-size: 16px;
  padding: 0 50px 0 15px;
}
.theme-main-menu .mobile-content .search-form button {
  position: absolute;
  width: 50px;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 6px;
  color: var(--heading);
  font-size: 20px;
  transition: all 0.3s ease-in-out;
}
.theme-main-menu .mobile-content .search-form button:hover {color: var(--red-one);}
.theme-main-menu .mobile-content .address-block .title {
  font-family: $font-poppins;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 15px;
}
.theme-main-menu .mobile-content .address-block p {
  font-size: 16px;
  line-height: 1.6em;
  color: var(--heading);
}
.theme-main-menu .mobile-content .address-block p a {font-size: 20px; color: var(--red-one); margin-top: 3px;}
.theme-main-menu .mobile-content .address-block p a:hover {text-decoration: underline;}








/*^^^^^^^^^^^^^^^^^^^^^ sidebar Menu ^^^^^^^^^^^^^^^^^^^^^^^^*/
.offcanvas.sidebar-nav {
  width: 500px;
  border: none;
  padding: 40px 40px 20px;
  max-height: 100%;
  overflow-y: auto;
}
.offcanvas.sidebar-nav .offcanvas-header {padding: 0;}
.sidebar-nav .offcanvas-header .close-btn {font-size: rem-calc(20px);color: #000;}
.sidebar-nav .offcanvas-header .close-btn:hover {color: var(--red-one);}
.sidebar-nav .sidebar-nav-item {padding: 5px 0 55px; text-align: center;}
.sidebar-nav .sidebar-nav-item .nav-item>a {
  font-size: rem-calc(24px);
  line-height: 2.42em;
  color: var(--heading);
  position: relative;
  transition: all 0.3s ease-in-out;
}
.sidebar-nav .sidebar-nav-item .nav-item:hover>a,
.sidebar-nav .sidebar-nav-item .nav-item.active>a {color: var(--red-one);}
.sidebar-nav .sidebar-nav-item .dropdown-toggle::after {display: none;}
.sidebar-nav .sidebar-nav-item .dropdown-toggle::before {
  content: "\f282";
  position: absolute;
  margin: 0;
  border: none;
  font-size: rem-calc(18px);
  right: -25px;
  top: -12px;
  font-family: "bootstrap-icons";
  font-weight: 700;
  transition: transform 0.3s ease-in-out;
}
.sidebar-nav .sidebar-nav-item .dropdown-toggle.show::before {transform: rotate(180deg);}
.sidebar-nav .sidebar-nav-item .dropdown-menu {
  position: static !important;
  border: none;
  background: var(--light-cyan);
  transform: scale(1) !important;
  padding: 10px 0;
  text-align: center;
}
.sidebar-nav .sidebar-nav-item .dropdown-menu a {
  font-size: rem-calc(18px);
  line-height: 44px;
  color: var(--heading);
  padding: 0 15px;
  background: transparent;
  transition: all 0.3s ease-in-out;
}
.sidebar-nav .sidebar-nav-item .dropdown-menu a:hover {color: var(--red-one);}
.sidebar-nav .sidebar-nav-item .dropdown-menu a span {position: relative;}
.sidebar-nav .sidebar-nav-item .dropdown-menu a span:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  bottom: -2px;
  background: var(--red-one);
  transform: scale(0 , 1);
  transform-origin: 0 0;
  transition: all 0.3s ease-in-out;
}
.sidebar-nav .sidebar-nav-item .dropdown-menu a:hover span:before {transform: scale(1);}
.sidebar-nav .search-form {
  margin: 20px auto 70px;
  width: 100%;
  position: relative;
}
.sidebar-nav .search-form input {
  width: 100%;
  height: 60px;
  border: 2px solid var(--heading);
  border-radius: 6px;
  font-size: rem-calc(17px);
  padding: 0 50px 0 20px;
}
.sidebar-nav .search-form button {
  position: absolute;
  width: 50px;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 6px;
  color: var(--heading);
  font-size: rem-calc(22px);
  transition: all 0.3s ease-in-out;
}
.sidebar-nav .search-form button:hover {color: var(--red-one);}
.sidebar-nav .address-block .title {
  font-family: $font-poppins;
  font-weight: 600;
  font-size: rem-calc(22px);
  padding-bottom: 14px;
  margin-bottom: 0;
}
.sidebar-nav .address-block p {
  font-size: rem-calc(19px);
  line-height: 1.6em;
  color: var(--heading);
  padding-bottom: 30px;
  margin-bottom: 0;
}
.sidebar-nav .address-block p a {font-size: rem-calc(22px); color: var(--red-one); margin-top: 2px;}
.sidebar-nav .address-block p a:hover {text-decoration: underline;}
.sidebar-nav .social-icon a {
  font-size: rem-calc(19px);
  margin-right: 19px;
  color: #333333;
  transition: all 0.3s ease-in-out;
}
.sidebar-nav .social-icon a:hover {color: var(--red-one);}
/*^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^*/


#offcanvasSearch {
  .wrap {
    display: flex;
    align-items: center;
    column-gap: 2rem;
    .search-form {
      margin: 0;
    }
  }
}
