@mixin bordered($side: 'right') {
  @include media-breakpoint-up(lg) {
    #{'border-#{$side}'}: 1px solid $theme-pink-light;

  }
}


#section__1 {
  @include padding-top-resp($xs: 50px, $sm: 60px, $md: 80px, $lg: 100px);
  @include padding-bottom-resp($xs: 50px);

  background: url("../../assets/images/background-effect.png");
  background-repeat: no-repeat;
  background-size: cover;

  .container {
    >.row {
      --bs-gutter-x: 12rem;

      // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
      @include media-breakpoint-up(md) {
        padding-bottom: 40px;

      }

      margin-bottom: 20px;

      .bordered {
        @include bordered;
      }

      .arrow-circle-img {

        img {
          @include position-bottom-center;
          width: 100px;

          // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
          @include media-breakpoint-down(lg) {
            bottom: -40px
          }
        }
      }

      .checklist-wrapper {
        img {
          margin-bottom: 30px;
        }

        h4 {
          margin-bottom: 35px;
          font-weight: 600;
        }
      }
    }
  }

  .job-offers {
    h2 {
      font-size: rem-calc(36px);
      font-weight: 600;
    }

    .job-offers-carousel {

      // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
      @include media-breakpoint-down(md) {
        @include make-container();
      }

      .glide__slides {
        padding: 30px 0 40px;

        // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
        @include media-breakpoint-up(md) {
          padding: 60px 0;
        }

        .glide__slide {
          &:hover {
            cursor: grab;
          }
        }
      }
    }

    .job-offers-controls {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .prev,
      .next {
        color: $theme-gray-light;
        font-size: rem-calc(18px);
        letter-spacing: 1.4px;
        display: flex;
        column-gap: 1rem;
        align-items: center;

        i {
          color: black;
          font-size: rem-calc(30px);
        }

        &:hover {
          opacity: .8;
        }
      }
    }
  }
}


#section__2 {
  @include padding-bottom-resp($xs: 60px, $sm: 100px);
  background: white;

  >img {
    height: 670px;
    width: 100%;
    object-fit: cover;
    @include padding-bottom-resp($xs: 40px, $sm: 70px);
  }

  .row:first-child {
    --bs-gutter-x: 6rem;

    .second-col {
      margin-top: 40px;

      // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
      @include media-breakpoint-up(lg) {
        margin-top: -200px;
      }

      .daily-menu-home {
        background: $theme-yellow;
        padding: 40px 50px 20px 50px;
        margin-bottom: 60px;

        .title {
          display: flex;
          align-items: center;
          column-gap: 2rem;
          margin-bottom: 30px;

          img {
            width: 65px;
            height: 65px;
          }

          h2 {
            font-size: rem-calc(32px);
            font-weight: 500;
          }
        }

        p {
          font-size: rem-calc(18px);
        }

        a {
          @extend .text-reset;
          font-size: rem-calc(30px);
          text-align: right;
          display: block;
          width: 100%;
        }
      }

      .restaurant {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;

        >img {
          border-radius: 50%;
          height: 250px;
          width: 250px;
          object-fit: cover;
          margin-bottom: 40px;

          // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
          @include media-breakpoint-between(lg, xxl) {
            height: 200px;
            width: 200px;
          }
        }

        p {
          font-size: rem-calc(20px);
          text-align: center;

          &:first-of-type {
            margin-bottom: 0;
          }

          strong {
            font-size: rem-calc(22px);
          }
        }

        .map-link {
          display: flex;
          align-items: center;
          column-gap: 1rem;
          font-size: 1rem;
          font-weight: 500;
          color: $theme-blue-light;
          text-align: center;
          margin-top: auto;
        }
      }
    }
  }
}

#section__3 {
  @include padding-top-resp($xs: 80px, $md: 80px);
  background: rgba(216, 216, 216, 0.2);

  .container {
    >.row {

      // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
      @include media-breakpoint-up(lg) {
        --bs-gutter-x: 6rem;
      }

      .bottom-padding-up-md {

        // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
        @include media-breakpoint-up(md) {
          padding-bottom: 60px
        }
      }

      .bordered {
        @include bordered('left');
        margin-top: 50px;
        padding-bottom: 110px;

        // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
        @include media-breakpoint-down(md) {
          padding-bottom: 50px;
        }
      }

      .arrow-circle-img {
        $horizontal-postion: calc(7 / 12 * 100%);

        img {
          @include absolute-position($top: null, $right: null, $bottom: 0,
            $left: $horizontal-postion);
          transform: translate(-50%, 50%);
          z-index: 1;
        }
      }
    }

    .accomodations {

      // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
      @include media-breakpoint-down(lg) {
        row-gap: 40px
      }

      .accommodation-info {
        >div {
          display: flex;
          flex-direction: column;
          align-items: center;

          img {
            margin-bottom: 30px;
          }

          p {
            font-size: rem-calc(20px);

            strong {
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  .gallery {
    .gallery-btn {
      @include pill-wrap('20%/50%');
      top: 9%;
      left: 10%;

      i {
        font-size: rem-calc(26px);
      }

      // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
      @include media-breakpoint-down(md) {
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .images-wrapper {
      >[class*='col-'] {
        padding: 0
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;

        &.left {

          // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
          @include media-breakpoint-down(md) {
            height: 500px
          }
        }
      }
    }
  }

  .info {
    padding-top: 60px;
    padding-bottom: 60px;

    >.row {
      >.col {
        &:first-of-type {

          // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
          @include media-breakpoint-up(lg) {
            border-right: 1px solid $theme-gray-light;
          }

          .wrapper {
            display: flex;

            // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
            @include media-breakpoint-up(lg) {
              justify-content: space-between;
            }

            // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
            @include media-breakpoint-down(lg) {
              padding-bottom: 40px;
              column-gap: 40px;
            }

            // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
            @include media-breakpoint-down(sm) {
              flex-direction: column;
              align-items: center;

              img {
                padding-bottom: 20px;
              }
            }

            .text {
              h3 {
                font-family: $font-noto;
                font-size: rem-calc(42px);
                margin: 0;
              }

              p {
                font-size: rem-calc(20px);
                color: $theme-gray;
              }
            }
          }
        }

        &:last-of-type {

          // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
          @include media-breakpoint-down(sm) {
            text-align: center;
          }

          h4 {
            font-size: rem-calc(22px);
            font-weight: 600;
            margin-bottom: 1rem;
          }

          p {
            font-size: rem-calc(22px);
          }
        }
      }
    }
  }
}

#section__4 {
  background: white;
  @include padding-top-resp($xs: 50px, $md: 80px);
  @include padding-bottom-resp($xs: 50px, $md: 80px);

  .title {
    display: flex;
    justify-content: space-between;
    padding-bottom: 60px;

    h2 {
      font-family: $font-noto;
      font-size: rem-calc(50px);
      font-weight: 300;

      .last-word {
        color: $theme-pink-light;
      }
    }
  }

  .latest-news {
    --bs-gutter-x: 6rem;

    // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
    @include media-breakpoint-down(xl) {
      --bs-gutter-x: 2rem;
      --bs-gutter-y: 2rem;
    }
  }
}

#section__5 {
  // background: rgba(216, 216, 216, 0.2);
  @include padding-top-resp($xs: 50px, $md: 60px);
  @include padding-bottom-resp($xs: 50px, $md: 60px);

  .row {
    --bs-gutter-x: 2rem;
    --bs-gutter-y: 2rem;

    // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
    @include media-breakpoint-down(xl) {}

    .col {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}