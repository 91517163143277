@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Averia+Serif+Libre:wght@300;400;700&family=Poppins:ital@1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Zilla+Slab:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400;500;600&display=swap');

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");


@import "./functions";
@import "./variables";
@import "./mixins";


// Include any default bootstrap variable overrides here (though functions won't be available)

$font-family-base: 'Poppins', sans-serif;


@import "../node_modules/bootstrap/scss/bootstrap";


// Then add additional custom code here

@media (max-width: 375px) {
  html {
    font-size: 90%;
  }
}

body {
  main {
    flex-grow: 1;
  }
}

button {
  border: none;
  outline: none;
  box-shadow: none;
  display: block;
  padding: 0;
  cursor: pointer;
  background: transparent;

  &:focus {
    outline: none;
  }
}

a {
  &:hover {
    opacity: .7;
  }

  text-decoration: none;
}

.logo a:hover {
  opacity: 1;
}

strong {
  font-weight: $font-weight-bold;
}


@import "./common";
@import "./homepage-slider";
@import "./menu";
@import "./teaser";
@import "./homepage";
@import "./content-page";
@import "./news";
@import "./footer";
@import "./daily_menu";
@import "./custom";