#carouselId {
  height: 900px;
  // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
  @include media-breakpoint-down(sm) {
    margin-top: -100px;
  }
  .carousel-inner {
    height: 100%;
    .carousel-item {
      height: 100%;
      img {
        height: 100%;
        object-fit: cover;
      }
      .carousel-caption {
        @extend .container;
        text-align: left;
        bottom: 28%;
        @include container-like-left-indent;
        // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
        @include media-breakpoint-down(sm) {
          top: 45%;
          transform: translateY(-45%);
        }
        .tagline {
          text-transform: uppercase;
          font-family: $font-poppins;
          font-weight: 600;
          font-size: rem-calc(22px);
          color: white;
          margin-bottom: 0;
          background: rgba(74, 74, 74, .7);
          width: fit-content;
          margin-bottom: 4px;
          padding: 0 6px;
        }
        .title {
          font-family: $font-averia;
          font-weight: 300;
          font-size: rem-calc(60px);
          color: white;
          background: rgba(74, 74, 74, .7);
          width: fit-content;
          line-height: rem-calc(65px);
          padding: 0 6px;
        }
      }
    }
  }
  .carousel-indicators {
    margin-bottom: 1rem;
  }
  .carousel-control-prev, .carousel-control-next {
    // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
    @include media-breakpoint-down(sm) {
      top: 30%;
      width: 10%;
    }
  }
  .carousel-actual {
    background-color: $theme-yellow;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    padding: 2rem;
    width: 90%;
    @media screen and (min-width: 1100px) {
      width: 1000px;
    }
    .row {
      > div:first-child {
        border-right: 4px solid $theme-pink-light;
      }
    }
    .meta {
      display: flex;
      font-size: 1rem;
      text-transform: uppercase;
      line-height: rem-calc(14px);
      margin-bottom: 10px;
      > div:not(:last-child) {
        padding-right: .5rem;
        margin-right: .5rem;
        border-right: 4px solid $theme-pink-light;
      }
    }
    .main {
      display: flex;
      align-items: flex-end;
      .text {
        font-weight: 600;
        font-size: 24px;
      }
      a {
        @extend .text-reset;
        i {
          font-size: rem-calc(30px);
        }
      }
    }
  }
}
