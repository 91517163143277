footer {
  @include padding-top-resp($xs: 50px, $md: 80px);
  background: #EDEDED;
  .row {
    @include padding-bottom-resp($xs: 20px, $sm: 50px, $md: 80px);
    --bs-gutter-x: 3rem;
    // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
    @include media-breakpoint-down(xl) {
      --bs-gutter-x: 1.5rem;
      --bs-gutter-y: 3rem;
    }
    @include media-breakpoint-down(md) {
      --bs-gutter-y: 2rem;
    }
  }
  .footer-list {
    @extend .style-none;
    li {
      font-size: rem-calc(18px);
      line-height: rem-calc(28px);
      font-weight: 500;
      display: flex;
      &::before {
        display: inline-block;
        margin-right: 10px;
        content: '\2014'
      }
    }
  }
  .wrapper {
    display: flex;
    align-items: flex-start;
    column-gap: 1rem;
    .text {
      p {
        font-size: rem-calc(18px);
        strong {
          font-weight: 600;
        }
        margin-bottom: 0;
      }
    }
  }
  .social-links {
    p {
      font-size: rem-calc(18px);
    }
    .social {
      display: flex;
      align-items: center;
      column-gap: 1rem;
    }
  }

  .logos-track {
    background-color: white;
    padding: 30px 0;
    .wrapper {
      width: 70%;

      // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
      @include media-breakpoint-down(lg) {
        flex-direction: column;

        img {
          margin-bottom: 1.5rem;
        }
      }

      @include media-breakpoint-down(xl) {
        width: 80%;
      }
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        height: 50px;
      }
    }
  }

  .bottom-line {
    padding: 30px 0;
    p {
      text-align: center;
      color: $theme-gray-light;
      font-size: rem-calc(14px);
      margin-bottom: 0;
    }
  }
}
