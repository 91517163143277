/////// Overlay

@mixin overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


/////// List

@mixin checklist($color: $theme-pink-light, $link-color: $theme-blue-light) {
  list-style: none;
  padding-left: 0;
  li {
    position: relative;
    font-size: rem-calc(20px);
    padding-bottom: .75rem;
    padding-left: 2rem;
    line-height: 1.5;
    a {
      color: $link-color;
      text-decoration: underline;
    }
    &::before {
      position: absolute;
      left: 0;
      top: 2px;
      content: url("data:image/svg+xml,<svg width='20' height='14' viewBox='0 0 20 14' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1.68028 6.58337L1 7.31411L6.77324 13H7.45352L19 1.69657L18.3197 1L7.11338 11.8873L1.68028 6.58337Z' fill='#{url-friendly-color($color)}' stroke='#{url-friendly-color($color)}'/></svg>");
      width: rem-calc(20px);
      height: rem-calc(26px);
      display: inline-block;
      margin-right: 1rem;
      height: 100%;
    }
  }
}


/////// Sizing

@mixin container-like-left-indent {
  // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
  @include media-breakpoint-down(sm) {
    left: 0;
  }
  @include media-breakpoint-only(sm) {
    left: calc((100vw - 540px) / 2);
  }
  @include media-breakpoint-only(md) {
    left: calc((100vw - 720px) / 2);
  }
  @include media-breakpoint-only(lg) {
    left: calc((100vw - 960px) / 2);
  }
  @include media-breakpoint-only(xl) {
    left: calc((100vw - 1140px) / 2);
  }
  @include media-breakpoint-only(xxl) {
    left: calc((100vw - 1320px) / 2);
  }
}


/////// Positioning

@mixin absolute-position($top: null, $right: null, $bottom: null, $left: null) {
  position: absolute;
  @if $top != null {
    top: $top;
  }
  @if $right != null {
    right: $right;
  }
  @if $bottom != null {
    bottom: $bottom;
  }
  @if $left != null {
    left: $left;
  }
}

// top positions
@mixin position-top-left {
  @include absolute-position($top: 0, $right: null, $bottom: null, $left: 0);
}
@mixin position-top-center {
  @include absolute-position($top: 0, $right: null, $bottom: null, $left: 50%);
  transform: translateX(-50%)
}
@mixin position-top-right {
  @include absolute-position($top: 0, $right: 0, $bottom: null, $left: null);
}

// middle positions
@mixin position-middle-left {
  @include absolute-position($top: 50%, $right: null, $bottom: null, $left: 0);
  transform: translateY(-50%);
}
@mixin position-middle-center {
  @include absolute-position($top: 50%, $right: null, $bottom: null, $left: 50%);
  transform: translate(-50%,-50%);
}
@mixin position-middle-right {
  @include absolute-position($top: 50%, $right: 0, $bottom: null, $left: null);
  transform: translateY(-50%);
}

// bottom positions
@mixin position-bottom-left {
  @include absolute-position($top: null, $right: null, $bottom: 0, $left: 0);
}
@mixin position-bottom-center {
  @include absolute-position($top: null, $right: null, $bottom: 0, $left: 50%);
  transform: translateX(-50%)
}
@mixin position-bottom-right {
  @include absolute-position($top: null, $right: 0, $bottom: 0, $left: null);
}


/////// Spacing

@mixin padding-top-resp($xs: null, $sm: null, $md: null, $lg: null, $xl: null, $xxl: null) {
  @if $xs != null {
    padding-top: $xs;
  }
  @if $sm != null {
    @include media-breakpoint-up(sm) {
      padding-top: $sm;
    }
  }
  @if $md != null {
    @include media-breakpoint-up(md) {
      padding-top: $md;
    }
  }
  @if $lg != null {
    @include media-breakpoint-up(lg) {
      padding-top: $lg;
    }
  }
  @if $xl != null {
    @include media-breakpoint-up(xl) {
      padding-top: $xl;
    }
  }
  @if $xxl != null {
    @include media-breakpoint-up(xxl) {
      padding-top: $xxl;
    }
  }
}

@mixin padding-bottom-resp($xs: null, $sm: null, $md: null, $lg: null, $xl: null, $xxl: null) {
  @if $xs != null {
    padding-bottom: $xs;
  }
  @if $sm != null {
    @include media-breakpoint-up(sm) {
      padding-bottom: $sm;
    }
  }
  @if $md != null {
    @include media-breakpoint-up(md) {
      padding-bottom: $md;
    }
  }
  @if $lg != null {
    @include media-breakpoint-up(lg) {
      padding-bottom: $lg;
    }
  }
  @if $xl != null {
    @include media-breakpoint-up(xl) {
      padding-bottom: $xl;
    }
  }
  @if $xxl != null {
    @include media-breakpoint-up(xxl) {
      padding-bottom: $xxl;
    }
  }
}


/////// Miscelaneous

@mixin pill-wrap($border-radius: '25%/50%') {
  background: white;
  padding: 1rem;
  border-radius: #{$border-radius};
  font-size: rem-calc(20px);
  font-weight: 600;
  width: fit-content;
}
