/////// Overlay

.overlay-1 { @include overlay; background-color: rgba(0, 0, 0, 0.1) }
.overlay-2 { @include overlay; background-color: rgba(0, 0, 0, 0.2) }
.overlay-3 { @include overlay; background-color: rgba(0, 0, 0, 0.3) }
.overlay-4 { @include overlay; background-color: rgba(0, 0, 0, 0.4) }
.overlay-5 { @include overlay; background-color: rgba(0, 0, 0, 0.5) }
.overlay-6 { @include overlay; background-color: rgba(0, 0, 0, 0.6) }
.overlay-7 { @include overlay; background-color: rgba(0, 0, 0, 0.7) }
.overlay-8 { @include overlay; background-color: rgba(0, 0, 0, 0.8) }
.overlay-9 { @include overlay; background-color: rgba(0, 0, 0, 0.9) }


/////// Typography (content pages text, new detail text)
.text--content {
  h1 {
    font-size: rem-calc(48px);
    font-weight: 600;
    line-height: rem-calc(72px);
  }
  h2 {
    font-size: rem-calc(36px);
    font-weight: 600;
    line-height: rem-calc(54px);
  }
  h3 {
    font-size: rem-calc(28px);
    font-weight: 600;
    line-height: rem-calc(42px);
  }
  h4 {
    font-size: rem-calc(24px);
    font-weight: 600;
    line-height: rem-calc(36px);
  }
  h5 {
    font-size: rem-calc(20px);
    font-weight: 600;
  }
  h6 {
    font-size: rem-calc(18px);
    font-weight: 600;
  }
  p {
    font-size: rem-calc(20px);
    line-height: rem-calc(36px);
    font-weight: 300;
    color: $theme-gray;
  }
  a {
    color: $theme-blue-light;
    text-decoration: underline;
    font-size: rem-calc(20px);
  }
  li {
    color: $theme-gray;
    font-size: rem-calc(20px);
  }
  ul, ol {
    padding-left: 3rem;
  }
}


/////// List

ul.checklist {
  @include checklist;
}


/////// Button

.btn-theme-blue {
  background-color: $theme-blue-dark;
  color: white;
  border: none;
  padding: rem-calc(18px 36px);
  border-radius: 6px;
  font-size: rem-calc(20px);
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  &:hover {
    opacity: 0.85;
  }
  // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
  @include media-breakpoint-down(md) {
    padding: rem-calc(14px 30px);
    font-size: rem-calc(18px);
  }
}


/////// News Card

.news-card {
  @mixin _tag {
    @include pill-wrap('10%/50%');
    background: $theme-pink-light;
    &__blue {
      background: $theme-blue-dark;
    }
    text-transform: uppercase;
    padding: 0 8px;
    color: white;
    font-size: rem-calc(16px);
    font-weight: 500;
    line-height: rem-calc(24px);
    position: absolute;
    left: 15px;
    bottom: 15px;
  }

  border-radius: 0;
  border: none;
  img {
    border-radius: 0;
  }
  .card-body {
    padding-left: 0;
    padding-right: 0;
    .card-text {

    }
    .card-link {
      color: #292929;
      font-weight: 600;
      font-size: 1rem;
    }
  }

  &__type-1 {
    .tag {
      @include _tag;
    }
    .card-body {
      .card-date {
        color: #696969;
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: 300;
        margin-bottom: 1rem;
      }
      .card-title {
        font-size: rem-calc(28px);
        margin-bottom: 1rem;
        line-height: rem-calc(42px);
      }
    }
  }

  &__type-2 {
    .tag {
      @include _tag;
      position: relative;
      top: 0;
      left: 0;
    }
    .card-body {
      padding-top: 0;
      .card-date {
        color: black;
        font-size: 1rem;
        font-weight: 500;
        span {
          color: #A7A7A7;
          font-size: rem-calc(17px);
          font-weight: 300;
        }
      }
      .card-title {
        font-size: rem-calc(26px);
        margin-bottom: 1rem;
        line-height: rem-calc(40px);
        a {
          color: black;
          text-decoration: underline;
        }
      }
    }
  }

  &__type-3 {
    height: 100%;
    .tag {
      @include _tag;
      position: relative;
      top: 0;
      left: 0;
      margin-bottom: 50px;
    }
    .card-body {
      padding-top: 0;
      display: flex;
      flex-direction: column;
      height: 100%;
      padding-bottom: 3rem;
      border-bottom: 3px solid black ;
      .card-title {
        font-size: rem-calc(26px);
        margin-bottom: 2rem;
        line-height: rem-calc(40px);
        a {
          color: black;
          text-decoration: underline;
        }
      }
      .card-date {
        margin-top: auto;
        color: black;
        font-size: 1rem;
        font-weight: 500;
        span {
          color: #A7A7A7;
          font-size: rem-calc(17px);
          font-weight: 300;
        }
      }
    }
  }
}


/////// Miscellaneous

.style-none {list-style: none; padding-left: 0; margin-bottom: 0;}

.pill-wrap { @include pill-wrap; }

.job-offer-card {
  background: white;
  padding: 30px 20px;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  .title {
    font-family: $font-zilla;
    font-size: rem-calc(30px);
    line-height: rem-calc(30px);
    display: flex;
    align-items: baseline;
    column-gap: 2rem;
    margin-bottom: 1rem;
    .tag {
      background: $theme-pink-light;
      font-family: $font-poppins;
      font-size: rem-calc(14px);
      font-weight: 600;
      text-transform: uppercase;
      color: white;
      letter-spacing: 1.5px;
      border-radius: 5px;
      padding: rem-calc(0 4px);
      line-height: 1.5;
    }
  }
  .employer {
    font-size: rem-calc(18px);
    font-weight: 300;
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
    padding-top: 40px;
    .price {
      color: $theme-pink-light;
      font-size: rem-calc(26px);
      font-weight: 300;
    }
    .date {
      font-size: rem-calc(18px);
      font-weight: 300;
    }
  }
}
