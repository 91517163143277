.header-jumbo {
  background: #F9F2EC;
  padding-top: 130px;
  padding-bottom: 90px;
  margin-top: 130px;
  text-align: center;
  // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
  @include media-breakpoint-down(md) {
    padding-top: 100px;
    padding-bottom: 80px;
  }
  @include media-breakpoint-down(sm) {
    padding-top: 55px;
    padding-bottom: 50px;
  }
  h1 {
    font-size: rem-calc(52px);
    color: $theme-gray;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  .separator-dash {
    color: $theme-pink-light;
    font-size: rem-calc(50px);
    line-height: .1;
    margin-bottom: 1.5rem;
  }
  p {
    color: $theme-gray;
    font-size: rem-calc(20px);
    font-weight: 300;
  }
}

.news-main {
  @include padding-top-resp($xs: 40px, $md: 80px, $xl: 100px);
  @include padding-bottom-resp($xs: 40px, $md: 80px, $xl: 100px);
  > .container {
    > .row {
      --bs-gutter-x: 5rem;
      --bs-gutter-y: 3rem;
    }
  }

  &.informator {
    > .container {
      > .row {
        --bs-gutter-x: 5rem;
        --bs-gutter-y: 5rem;
      }
    }
  }

  &.detail {
    .featured-image {
      width: 100%;
      object-fit: cover;
      padding-bottom: 3rem;
    }
  }

}

.pagination {
  display: flex;
  align-items: baseline;
  column-gap: .5rem;
  .page-item {
    .page-link {
      position: relative;
      font-size: rem-calc(24px);
      font-weight: 600;;
      color: black;
      text-decoration: none;
      background-color: transparent;
      border: none;
      i {
        font-size: rem-calc(26px);
        padding-bottom: 0;
      }
    }
    &.first, &.last {
      .page-link {
        font-size: rem-calc(22px);
        color: $theme-gray;
        font-weight: 500;
      }
    }
    &.active {
      .page-link {
        color: $theme-pink-light;
        cursor: pointer;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  .dots {
    font-size: 2rem;
  }
}

.header-jumbo-detail {
  background: #F9F2EC;
  padding: 100px 50px 90px;
  margin-top: 130px;
  // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px
  @include media-breakpoint-down(md) {
    padding: 130px 50px 90px;
  }
  @include media-breakpoint-down(sm) {
    padding: 130px 50px 90px;
  }
  .tagline {
    text-transform: uppercase;
    font-weight: 300;
    font-size: 1rem;
    color: $theme-gray;
    display: flex;
    align-items: center;
    column-gap: .5rem;
    margin-bottom: 1rem;
    .separator {
      border-right: 3px solid $theme-pink-light;
      height: 1rem;
    }
  }
  h1 {
    font-size: rem-calc(42px);
    color: $theme-gray;
    margin-bottom: 1rem;
    width: 70%;
    font-weight: 400;
  }
}

aside {
  h3 {
    color: $theme-gray;
    font-size: rem-calc(36px);
    margin-bottom: 2rem;
  }
  nav.news-sections {
    ul {
      li {
        font-size: rem-calc(20px);
        padding-bottom: 1rem;
        &.active {
          color: $theme-pink-light;
        }
        &::before {
          display: inline-block;
          margin-right: 10px;
          content: '\2014'
        }
      }
    }
  }
}
